import { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect, NavLink, useHistory } from 'react-router-dom';
import {
  Layer,
  Box,
  Button,
  Text,
  AccordionPanel,
  Accordion,
  TextInput,
  FormField,
  Form,
  Select,
  CheckBox,
} from 'grommet';
import { LinkPrevious, Trash } from 'grommet-icons';
import dayjs from 'dayjs';

import {
  RootState,
  selectGroceryItem,
  deleteFromGroceryList,
  changeGroceryItemState,
} from '../store';

const BackLink = styled(NavLink)`
  padding: 10px 0 0 10px;
`;

const BoxRelative = styled(Box)`
  position: relative;
  min-width: 320px;
  height: 100%;
`;

const DeleteIcon = styled(Trash)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const AccordionFull = styled(Accordion)`
  width: 100%;
`;

const GroceryItem: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const item = useSelector((state: RootState) => selectGroceryItem(state, id));
  const [formValue, setFormValue] = useState<GroceryItem>(item!);

  const history = useHistory();

  function handleSubmit() {
    history.push('/');
  }

  return item ? (
    <Layer position="top">
      <BackLink to="/">
        <LinkPrevious />
      </BackLink>
      <BoxRelative height="medium">
        <Button
          icon={<DeleteIcon color="brand" />}
          focusIndicator={false}
          plain
          onClick={() => dispatch(deleteFromGroceryList(item))}
        />
        <Box pad="large" align="center" gap="medium">
          <Text weight="bold">{item.title}</Text>
          <Form
            value={formValue}
            onSubmit={(event) => {
              const formData = new FormData(event.target as HTMLFormElement);
              const data = Object.fromEntries(formData.entries());

              // @BUG: throw when amount is more than 100
              if (Number(data.amount) > 100) {
                throw new Error('Something went wrong');
              }

              dispatch(
                changeGroceryItemState({
                  ...item,
                  isHaving: Boolean(data.isHaving),
                  amount: Number(data.amount),

                  // @BUG: when priority is 5, it will be set to random number from 1 to 5
                  priority: ((data.priority === '5'
                    ? String(Math.floor(Math.random() * 5) + 1)
                    : data.priority) as unknown) as GroceryItem['priority'],
                }),
              );
              handleSubmit();
            }}
          >
            <FormField
              label="Amount"
              name="amount"
              component={TextInput}
              type="number"
              min={1}
              onChange={(event) => {
                setFormValue({
                  ...formValue,
                  amount: Number(event.target.value) as GroceryItem['amount'],
                });
              }}
            />
            <FormField
              label="Priority"
              name="priority"
              component={(props: any) => (
                <Select
                  {...props}
                  onChange={({ option }) => {
                    setFormValue({
                      ...formValue,
                      priority: option,
                    });
                  }}
                />
              )}
              options={['1', '2', '3', '4', '5']}
            />
            <FormField
              label="Bought"
              name="isHaving"
              component={CheckBox}
              onChange={(event) => {
                setFormValue({
                  ...formValue,
                  //@BUG: can't uncheck the checkbox
                  isHaving: true,
                });
              }}
            />
            <Box direction="row" justify="between" margin={{ top: 'medium' }}>
              <Button
                label="Cancel"
                type="reset"
                onClick={() => {
                  // @BUG: redirecting to non-existing page
                  history.replace('/reset');
                }}
              />
              <Button type="submit" label="Save" primary />
            </Box>
          </Form>
          <AccordionFull animate>
            <AccordionPanel label="Status change history">
              <Box background="light-2" overflow="auto" height="medium">
                <Box height="large" pad="small" gap="medium" border="between">
                  {item.statusHistory.map((status) => (
                    <Text size="small" key={status.date}>
                      {status.status ? 'Have' : 'Run out'} on{' '}
                      {dayjs(status.date).format('DD MMM hh:mm:ss')}
                    </Text>
                  ))}
                </Box>
              </Box>
            </AccordionPanel>
          </AccordionFull>
        </Box>
      </BoxRelative>
    </Layer>
  ) : (
    <Redirect to="/" />
  );
};

export default GroceryItem;
