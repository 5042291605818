import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Box, Button, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import dayjs from 'dayjs';

import Switch from '../components/Switch';
import { deleteFromGroceryList, changeGroceryItemState } from '../store';

const DeleteIcon = styled(Trash)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const BoxRelative = styled(Box)`
  position: relative;
`;

const BuggyBatton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Item: FC<{ item: GroceryItem }> = ({ item }) => {
  const dispatch = useDispatch();

  // @BUG: showing random dates from the array of status changing history
  const randomStatus =
    item.statusHistory[Math.floor(Math.random() * item.statusHistory.length)];
  const date = dayjs(randomStatus.date);

  return (
    <BoxRelative
      pad="large"
      align="center"
      background={{ color: 'light-2', opacity: 'strong' }}
      round
      gap="small"
    >
      {/* @BUG: buggy UI, flashing button frame on click */}
      <BuggyBatton
        icon={<DeleteIcon color="brand" />}
        // focusIndicator={false}
        // plain
        onClick={() => dispatch(deleteFromGroceryList(item))}
      />
      <Text weight="bold">{item.title}</Text>
      <Text size="small">Priority: {item.priority}</Text>
      <Text size="small">Amount: {item.amount}</Text>
      <Text size="small">Last update: {date.format('DD MMM hh:mm:ss')}</Text>
      <Switch
        checked={item.isHaving}
        onChange={(checked) =>
          dispatch(
            changeGroceryItemState({
              ...item,
              isHaving: checked as boolean,
            }),
          )
        }
      />
      <Button
        as={({ className }) => (
          <NavLink className={className} to={`/item/${item.id}`}>
            Open
          </NavLink>
        )}
      />
    </BoxRelative>
  );
};

export default Item;
